import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ApiHook } from "../../hooks/apiHook";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import { t } from "i18next";
import { useNavigate } from "react-router";

const SingleBoard = ({ ind, val, currentBoard, perpectualCycle }) => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const endTutorialMutation = ApiHook.CallPlatformFee();
  let [warning, setWarning] = useState(false);

  const contactUsSubmit = () => {
    const data = {
      board: val.id,
    };
    endTutorialMutation.mutate(data);
  };

  const submitBoard = () => {
    if (val.feeSubmitted == false) {
      return toast.error(t("feeApprovalNeeded"));
    } else if (val.id == 1 && perpectualCycle == 1) {
      const path = `/board-view/${val.id}/confirm`;
      navigate(path);
    } else if (val.id != currentBoard + 1 || !val.okayToJoin) {
      setWarning(true);
    } else {
      const path = `/board-view/${val.id}/confirm`;
      navigate(path);
    }
  };

  return (
    <div className="" key={ind}>
      <div
        className="board-view_cards"
        style={{ opacity: val.bought ? "100%" : "50%" }}
        onClick={handleModalShow}
      >
        <img src={val.image} alt="" />
      </div>
      <Modal show={modalShow} onHide={handleModalClose} centered>
        {warning ? (
          <Modal.Body>
            <div
              className="warning-image-container"
              style={{ marginTop: "5px" }}
            >
              <div class="warning-background-container">
                <img src={"images/LOGO.png"} alt="" />
              </div>
            </div>
            <div className="modal-text-middle" style={{ color: "red" }}>
              You cannot log in because you have not completed your previous
              board.
              <br />
              ¡THANK YOU!
            </div>
            <br />
          </Modal.Body>
        ) : (
          <>
            <Modal.Body>
              <div className="dashboard_modal_image">
                <img src={val.image} alt="" />
              </div>
              <div className="modal-text-middle">{val.text}</div>
              <br />
              {val.platformFee > 0 && (
                <div className="whatsapp-button-container">
                  <Button
                    variant="secondary"
                    className="button-success-whatsapp"
                    onClick={contactUsSubmit}
                    disabled={endTutorialMutation.isLoading}
                  >
                    {endTutorialMutation.isLoading ? (
                      t("loading")
                    ) : (
                      <>
                        <img
                          src="images/whatsapp.png"
                          alt="WhatsApp Logo"
                          className="modal_icon_media"
                        />
                        {t("contactUs")}
                      </>
                    )}
                  </Button>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="modal-foot-button-succuss"
                onClick={submitBoard}
              >
                {t("confirmEntry")}
              </Button>
              <Button
                variant="primary"
                className="modal-foot-button-danger"
                onClick={handleModalClose}
              >
                {t("Deny")}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
};

export default SingleBoard;
